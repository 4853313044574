import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { TestService } from "../../providers/test.service";
import { LangService } from "../../providers/lang.service";

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  questions: any = [];
  questionNumber: number = 1;
  countQuestions: number = 0;
  progressValue: number = 0;

  currentLangIso: string = this.langService.currentLangIso;

  constructor(public langService: LangService, private router: Router, private testService: TestService) {
    this.listenEvents();
    this.testService.updateToolbar(false);
  }

  ngOnInit() {
    if (!this.testService.memberInfo) {
      this.router.navigateByUrl('/');
    }

    this.testService.getQuestions().then((questions) => {
      this.questions = questions;
      this.countQuestions = this.testService.countQuestions;
    });
  }

  listenEvents() {
    this.testService.listenQuestions().subscribe((questions) => {
      this.questions = questions;
      this.questionNumber = 1;
      this.progressValue = 0;
    });
  }

  nextQuestion(index: number): void {
    this.changeQuestion(index, true, 100);
  }

  prevQuestion(index: number): void {
    this.changeQuestion(index, false, 0);
  }

  changeQuestion(index: number, increase: boolean, complete: number): void {
    index = increase ? index + 1 : index - 1;

    if ('undefined' !== typeof this.questions[index]) {
      this.questions[this.questionNumber - 1]['visible'] = false;
      this.questions[index]['visible'] = true;

      let progressValue: number = Math.ceil(1 / this.countQuestions * 100 * index);

      this.progressValue = increase ? progressValue + 1 : progressValue - 1;
      this.questionNumber = increase ? this.questionNumber + 1 : this.questionNumber - 1;
    } else {
      this.progressValue = complete;
    }
  }

  setScore(index: number, score: number) {
    this.questions[index]['score'] = score;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    let key: any = event.key,
        index: number = this.questionNumber - 1;

    if (!!this.questions[index].score && (key === 'ArrowRight' || key === 'Enter')) {
      if (this.questionNumber == this.questions.length && (key === 'ArrowRight' || key === 'Enter')) {
          this.finishTest();
      } else {
          this.nextQuestion(index);
      }
    } else if (!!index && (key === 'ArrowLeft' || key === 'Backspace')) {
      this.prevQuestion(index);
    }

    parseInt(key) > 0 && parseInt(key) <= 6 && this.setScore(index, key);
  }

  finishTest() {
    this.testService.finishTest(this.questions);
  }

}
