import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { MemberFormComponent } from './components/member-form/member-form.component';
import { TestComponent } from './components/test/test.component';
import { ResultComponent } from './components/result/result.component';
import { HistoryComponent } from './components/history/history.component';
import { AboutComponent } from './components/about/about.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },{
        path: 'member-form',
        component: MemberFormComponent
    },{
        path: 'test',
        component: TestComponent
    },{
        path: 'result',
        component: ResultComponent
    },{
        path: 'history',
        component: HistoryComponent
    },{
        path: 'about',
        component: AboutComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
