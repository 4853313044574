import { Component } from '@angular/core';

import { LangService } from "../../providers/lang.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  currentLangIso: string = this.langService.currentLangIso;

  constructor(public langService: LangService) { }

}
