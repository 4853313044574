import { Component } from '@angular/core';

import { TestService } from "../../providers/test.service";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  toolbar: boolean = true;

  constructor(public testService: TestService) {
    this.listenEvents();
  }

  listenEvents() {
    this.testService.listenToolbar().subscribe((res) => {
      this.toolbar = res;
    });
  }

  isWebSQL() {
    return !!(<any> window).openDatabase;
  }

}
