import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

import { LangService } from "../../providers/lang.service";
import { TestService } from "../../providers/test.service";
import { HelperService } from '../../providers/helper.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  displayedColumns = ['name', 'age', 'sex', 'created_at'];
  dataSource = new MatTableDataSource;

  resultsLength = 0;

  currentLangIso: string = this.langService.currentLangIso;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public langService: LangService, private test: TestService) { }

  ngOnInit() {
    this.test.getResults().then((response) => {
      if (response) {
        let data:any[] = HelperService.setIdsByKeys(response);
        this.dataSource = new MatTableDataSource(data.reverse());
        this.resultsLength = data.length;

        setInterval(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      }
    }, (e) => {
      console.log(e);
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    this.dataSource.filter = filterValue;
  }

}
