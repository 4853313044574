import { Injectable } from '@angular/core';

@Injectable()
export class DbService {

  db: any;

  constructor() { }

  init(): void {
      if ((<any> window).openDatabase) {
          this.db = (<any> window).openDatabase('giz', '1.0', 'A list of members.', 5 * 1024 * 1024);

          this.db.transaction((tx) => {
              tx.executeSql('CREATE TABLE IF NOT EXISTS TESTS (name TEXT, age INTEGER, sex TEXT, created_at REAL)');
              tx.executeSql('CREATE TABLE IF NOT EXISTS TEST_ITEM_SCORES (test_id INTEGER, item TEXT, score INTEGER)');
          });
      }
  }

  insert(table: string, columns: string, values: string, data): Promise<any> {
      return new Promise((reject) => {
          if ((<any> window).openDatabase) {
              this.db.transaction((tx) => {
                  tx.executeSql('INSERT INTO ' + table + ' (' + columns + ') VALUES (' + values + ');', data, (tx, result) => {
                      reject(result.insertId);
                  });
              });
          } else {
              reject();
          }
      });
  }

  all(table: string): Promise<any> {
      return new Promise((reject) => {
          if ((<any> window).openDatabase) {
              this.db.transaction((tx) => {
                  tx.executeSql('SELECT * FROM ' + table + ';', [], (tx, result) => {
                      reject(result);
                  });
              });
          } else {
              return;
          }
      });
  }
}
