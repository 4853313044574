import { Injectable } from '@angular/core';

import { TestService } from "./test.service";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LangService {
  langList: object = [
    {iso: 'ru', name: 'Русский'},
    {iso: 'kg', name: 'Кыргызча'}
  ];

  currentLangIso: string = this.langList[0].iso;

  constructor(public translate: TranslateService, public testService: TestService) {

  }

  init(): Promise<any> {
    return new Promise((reject) => {
      let currentLangIso: string = localStorage.getItem('currentLangIso');

      if (currentLangIso !== null) {
        this.currentLangIso = currentLangIso;
      } else {
        localStorage.setItem('currentLangIso', this.currentLangIso);
      }

      this.translate.setDefaultLang(this.currentLangIso);
      this.testService.updateLangIso();

      reject(this.currentLangIso);
    });
  }

  setDefaultLang(lang: string = this.currentLangIso): void {
    this.currentLangIso = lang;
    this.translate.setDefaultLang(lang);

    localStorage.setItem('currentLangIso', lang);

    this.testService.updateQuestions();
  }

}
