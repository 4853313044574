import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

import { HelperService } from './helper.service';
import { DbService } from './db.service';

// import { remote } from 'electron';

@Injectable()
export class TestService {
  private questions = new Subject<any>();
  private toolbar = new Subject<any>();

  currentLangIso: string;
  countQuestions: number = 0;
  memberInfo: any = null;
  competencesScores: any = {professional: [], social: [], personal: [], methodical: []};

  constructor(
    public helper: HelperService,
    private http: HttpClient,
    private router: Router,
    private db: DbService
  ) {

  }

  getQuestions(): Promise<any> {
    return new Promise ((resolve) => {
      // let pathJSON = require('path').join(remote.app.getPath('appData'), 'data.json');
      // resolve(this.parseSystemJSON(pathJSON));

      this.parseRemoteJSON('assets/tests.json').then((test) => {
        let questions = this.processValues(test);
        this.countQuestions = questions.length;

        resolve(questions);
      }, (error) => {
        if ('undefined' !== typeof error.name && 'HttpErrorResponse' === error.name) {
          this.helper.openDialog('Oops!', error.message, 'warn');
        }
      });
    });
  }

  getCompetencesScores(): object {
    let result: any = {professional: [], social: [], personal: [], methodical: []};

    for (let indexCompetences in this.competencesScores) {
      for (let indexGroups in this.competencesScores[indexCompetences]) {
        let original = this.competencesScores[indexCompetences][indexGroups].score;
        let data = original.toString().split('.');

        let level: object = [];

          for (let indexLevel = 0; indexLevel < 6; indexLevel++) {
          let processNumber: number;

          if ('undefined' !== typeof original) {
            if (indexLevel == data[0]) {
              processNumber = parseInt(data[1]); /* parseInt(data[1]) >= 50 ? 100 : 0 */
            } else if (indexLevel < data[0]) {
              processNumber = 100;
            } else {
              processNumber = 0;
            }
          } else {
            if (indexLevel < data[0]) {
              processNumber = 100;
            } else {
              processNumber = 0;
            }

          }

          level[indexLevel] = processNumber;
        }

        result[indexCompetences].push({
          group: this.competencesScores[indexCompetences][indexGroups].title,
          floatScore: parseFloat(this.competencesScores[indexCompetences][indexGroups].score),
          originalScore: Math.round(original),
          level: level,
          percent: Math.round(original) * 100 / 6
        });
      }
    }

    return result;
  }

  getResults(): Promise<any> {
    return new Promise ((resolve) => {
      this.db.all('TESTS').then((results) => {
        resolve(results.rows);
      });
    });
  }

  updateQuestions(): void {
    this.updateLangIso();

    this.getQuestions().then((questions) => {
      this.questions.next(questions);
    });
  }

  listenQuestions(): Observable<any> {
    return this.questions.asObservable();
  }

  updateToolbar(show: boolean = true): void {
    this.toolbar.next(show);
  }

  listenToolbar(): Observable<any> {
    return this.toolbar.asObservable();
  }

  setMemberInfo(memberInfo: object): void {
    this.memberInfo = memberInfo;
  }

  finishTest(questions: object): void {
    this.processScores(questions).then(() => {
      this.router.navigateByUrl('/result');
    });
  }

  updateLangIso(): void {
    this.currentLangIso = localStorage.getItem('currentLangIso');
  }

  private processScores(questions: object): Promise<any> {
    return new Promise((reject) => {
      this.competencesScores = {professional: [], social: [], personal: [], methodical: []};

      let data = [this.memberInfo.name, this.memberInfo.age, this.memberInfo.sex, new Date().getTime()];

      this.db.insert('TESTS', 'name, age, sex, created_at', '?, ?, ?, ?', data).then((testId) => {
        for (let i in questions) {
          if (typeof this.competencesScores[questions[i].competence.key][questions[i].group.index] == 'undefined') {
              this.competencesScores[questions[i].competence.key][questions[i].group.index] = {
                index: questions[i].group.index,
                title: questions[i].group.title,
                competence: questions[i].competence.key,
                score: parseInt(questions[i].score)
              };
          } else {
              this.competencesScores[questions[i].competence.key][questions[i].group.index].score += parseInt(questions[i].score);
          }

          // let data = [testId, questions[i]['title'], questions[i]['score']];
          // this.db.insert('TEST_ITEM_SCORES', 'test_id, item, score', '?, ?, ?', data);
        }

        for (let competenceKey in this.competencesScores) {
          for (let groupKey in this.competencesScores[competenceKey]) {
            this.competencesScores[competenceKey][groupKey].score =
              this.competencesScores[competenceKey][groupKey].score / 2;
            this.competencesScores[competenceKey][groupKey].score = this.competencesScores[competenceKey][groupKey].score.toFixed(2);
          }
        }

        reject();
      });
    });
  }

  private processValues(data: any, shuffle: boolean = true): any {
    if (data.length) {
      let result: any = [];
      let questions: any = [];

      let currentIndex: number = 1;

      for (let indexL1 = 0; indexL1 < data.length; indexL1++) {
        let groups: any = data[indexL1].groups;

        if (shuffle) {
          questions[indexL1] = [];
        }

        for (let indexL2 = 0; indexL2 < groups.length; indexL2++) {
          let items: any = groups[indexL2].items;

          for (let indexL3 = 0; indexL3 < items.length; indexL3++) {
            let item: object = {
              competence: {
                key: data[indexL1].key,
                title: data[indexL1].title[this.currentLangIso]
              },
              group: {
                  index: indexL2,
                  title: data[indexL1].groups[indexL2].title[this.currentLangIso]
              },
              title: items[indexL3].title[this.currentLangIso],
              score: 0,
              visible: false
            };

            if (shuffle) {
                questions[indexL1].push(item);
            } else {
                questions.push(item);
            }

            currentIndex++;
          }
        }

        if (shuffle) {
          questions[indexL1] = this.shuffle(questions[indexL1]);
        }
      }

      if (shuffle) {
          for (let i in questions) {
              result = result.concat(questions[i]);
          }
      } else {
          result = questions;
      }

      if (typeof result[0].visible !== 'undefined') {
          result[0].visible = true;
      }

      return result;
    }

    return false;
  }

  private shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  private parseRemoteJSON(fileUrl: string): Promise<any> {
    return new Promise ((resolve, reject) => {
      this.http.get(fileUrl).subscribe(response => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  /*
  private parseSystemJSON(fileUrl: string): any {
    require('fs').readFile(fileUrl, 'utf8', (error, text) => {
      if (error) {
        this.helper.openDialog('Oops!', error, 'warn');
        return false;
      }

      let data: object;
      if (data = JSON.parse(text)) {
        return data;
      } else {
        this.helper.openDialog('Oops!', 'Something went wrong', 'warn');
        return false;
      }
    });
  }
  */
}
