import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { LangService } from "../../providers/lang.service";
import { TestService } from "../../providers/test.service";
import { HelperService } from "../../providers/helper.service";

declare var html2canvas: any;

// import { remote } from "electron";

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  results: any = [];
  currentLangIso: string = this.langService.currentLangIso;

  constructor(
    public translate: TranslateService,
    public langService: LangService,
    public helperService: HelperService,
    private router: Router,
    private testService: TestService
  ) {
    this.testService.updateToolbar(true);
  }

  ngOnInit() {
    this.results = this.testService.getCompetencesScores();

    if (!this.testService.memberInfo || this.results.professional.length === 0) {
        this.router.navigateByUrl('/');
    }
  }

  printPage() {
    html2canvas(document.querySelector(".results"), {
      onrendered: function(canvas) {
        const dataUrl = canvas.toDataURL();

        let html = '<html>';
              html+= '<head>';
                html+= '<title></title>';
             html+= '</head>';
              html+= '<body onload="window.print()" style="text-align: center;">';
                html+= '<img src="' + dataUrl + '" height="100%">';
              html+= '</body>';
            html+= '</html>';

          let win = window.open('about:blank', '_new');
          win.document.open();
          win.document.write(html);
          win.document.close();
      }
    });
  }

}
