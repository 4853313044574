import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

import { DialogComponent } from '../components/dialog/dialog.component';

import * as moment from 'moment';

@Injectable()
export class HelperService {

  constructor(public dialog: MatDialog) { }

  static setIdsByKeys(data: any[]): Array<{}> {
    let result: any[] = [];

    if (typeof data === 'object') {
      let i = 0;

      for (let index in data) {
        if ('object' == typeof data[index]) {
          result[i] = data[index];

          if ('undefined' !== typeof result[i].created_at) {
            result[i].created_at = moment(parseInt(result[i].created_at)).locale('ru').format('LLL');
          }
        }

        i++;
      }
    }

    return result;
  }

  openDialog(title: string, message: string, color: string = 'primary'): void {
    this.dialog.open(DialogComponent, {
      width: '360px',
      data: { title: title, message: message, color: color }
    });
  }

  openContentDialog(title: string, message: string, color: string = 'primary'): void {
    this.dialog.open(DialogComponent, {
      width: '500px',
      data: { title: title, message: message, color: color }
    });
  }

}
