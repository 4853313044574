import { Component } from '@angular/core';

import { LangService } from "./providers/lang.service";
import { DbService } from "./providers/db.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(lang: LangService, db: DbService) {
        db.init();

        lang.init().then();
    }

}