import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { TestService } from "../../providers/test.service";
import { LangService } from "../../providers/lang.service";

@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.scss']
})
export class MemberFormComponent {

  formGroup: FormGroup;
  langList: object;
  currentLangIso: string;
  age: any = [];

  constructor(fb: FormBuilder, public langService: LangService, private router: Router, private test: TestService) {
    this.formGroup = fb.group({
      hideRequired: true
    });

    this.formGroup = new FormGroup({
      name: new FormControl(),
      age: new FormControl(),
      sex: new FormControl(),
      lang: new FormControl()
    });

    this.langList = this.langService.langList;
    this.currentLangIso = this.langService.currentLangIso;

    for (let i = 10; i <= 55; i++) {
      this.age.push(i);
    }
  }

  setDefaultLang(lang: string = this.currentLangIso): void {
    this.langService.setDefaultLang(lang);
    this.currentLangIso = lang;
  }

  onSubmit(form) {
    if (form.valid) {
      let data = form.form.value;

      form.resetForm();
      this.test.setMemberInfo(data);
      this.router.navigateByUrl('/test');
    }
  }

}
