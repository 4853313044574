import { Component } from '@angular/core';

import { LangService } from "../../providers/lang.service";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

  currentLangIso: string = this.langService.currentLangIso;

  constructor(public langService: LangService) { }

}
